// modules/boards.js
import axios from 'axios'
import { WIZARD_LIST } from '@/constants/api.js'

const INITIAL_STATE = () => ({
  docs: [],
  boardId: null,
})

export default {
  namespaced: true,
  state: INITIAL_STATE(),
  actions: {
    UPDATE_BOARD_ID({ commit }, payload) {
      commit('UPDATE_CURRENT_BOARD_ID', payload)
    },
    async FETCH_WIZARD({ commit, rootGetters, state }) {
      try {
        Object.assign(state, INITIAL_STATE())
        const authorization = rootGetters['auth/headers']
        const headers = {
          'Content-Type': 'application/json',
          ...authorization,
        }
        const { data: response } = await axios.get(WIZARD_LIST, { headers })
        commit('STORE_SUCCESS', response.data)
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
  },
  mutations: {
    UPDATE_CURRENT_BOARD_ID(state, payload) {
      state.boardId = payload
    },
    STORE_SUCCESS(state, payload) {
      state.docs = payload
    },
  },
  getters: {
    docs: (state) => state.docs,
    boardId: (state) => state.boardId,
  },
}
