<template>
  <v-app>
    <v-main class="blue-grey lighten-5" v-if="allowed">
      <router-view></router-view>
    </v-main>
    <div v-else class="container">
        <h1>404</h1>
        <p>Sorry, the page you are looking for cannot be found.</p>
    </div>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { URL_RADAR } from './constants/api'

export default {
  name: 'App',
  data() {
    return {
      allowed: false,
    }
  },
  async mounted() {
    window.addEventListener('message', await this.receiveMessage)
  },
  async beforeDestroy() {
    window.removeEventListener('message', await this.receiveMessage)
    this.destroyToken()
  },
  methods: {
    ...mapActions({
      storeToken: 'auth/STORE_ACCESS_TOKEN',
      destroyToken: 'auth/DESTROY_TOKEN'
    }),
    async receiveMessage(event) {
      // Verify the origin of the message for security
      if (event.origin === URL_RADAR) {
        const { type, token } = event.data
        if (type === 'ACCESS_TOKEN') {
          this.allowed = true
          // Store th token
          await this.storeToken({ token })
        }
      }

    }
  }
}
</script>
<style scoped>
.container {
  margin-top: 200px;
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
</style>