// modules/auth.js
const INITIAL_STATE = () => ({
  headers: null,
  user: null,
})

export default {
  namespaced: true,
  state: INITIAL_STATE(),
  actions: {
    async STORE_ACCESS_TOKEN({ commit }, payload) {
      try {
        const { token } = payload
        const headers = { Authorization: `Bearer ${token}` }
        commit('STORE_HEADER', { headers })
      } catch (err) {
        commit('LOGIN_FAILED', err.message)
      }
    },
    DESTROY_TOKEN({ commit }) {
      commit('REMOVE_TOKEN')
    }
  },
  mutations: {
    STORE_HEADER(state, payload) {
      state.headers = payload.headers
    },
    FETCH_AUTHENTICATED_USER_SUCCESS(state, payload) {
      state.user = Object.assign(payload, { loadedAt: Date.now() })
    },
    REMOVE_TOKEN(state) {
      Object.assign(state, INITIAL_STATE())
    },
  },
  getters: {
    authenticated: (state) => !!state.headers,
    // user: (state) => state.user,
    headers: (state) => state.headers,
  },
}
