let BASE_URL = null
let HOSTNAME_RADAR = null
const { hostname } = window.location

if (hostname === 'localhost') {
  BASE_URL = 'http://localhost:4040'
  HOSTNAME_RADAR = 'http://localhost:8080'
} 
if (hostname === 'wizard-module.wenuwork.cl') {
  BASE_URL = 'https://ws.wenuwork.cl'
  HOSTNAME_RADAR = 'https://radar.wenuwork.cl'
}

export const URL_RADAR = HOSTNAME_RADAR
export const WIZARD_LIST = `${BASE_URL}/wizard-forms`
export const WIZARD_BY_ID = `${BASE_URL}/wizard-forms/:wizardId`
